<template>
<div>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col>
        <b-card>
          <div class="mb-2" style="display: flex; height: 30px;">
            <filter-interface class="mr-4" namespace="cprd" :rows="initiativesPolicies" v-model="rowsFiltered" :staticDataProperties="staticDataProperties"></filter-interface>
            <search-interface namespace="cprd" :rows="rowsFiltered" v-model="rows"></search-interface>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
          <initiative :item="item" :trim="400"></initiative>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import Initiative from '@/components/InitiativeCPRD.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'

export default {
  name: 'PoliciesTable',
  components: {
    FilterInterface,
    Initiative,
    SearchInterface
  },
  computed: {
    countries: function () {
      // get countries from a country property of this.rows, sorth them alphabetically, and remove duplicates
      const countries = _.sortBy(_.uniq(_.map(this.rows, 'country')))
      return countries
    },
    initiativesPolicies: function () {
      return this.$store.state.initiativesPolicies
    },
    policies: function () {
      let policies = []
      _.each(this.rows, (row) => {
        if (row.impactpolicy?.cpd) { // only include policies marked as cpd = true
          policies.push({
            id: row.impactpolicy_id,
            name: row.impactpolicy.name,
            area: row.impactpolicy.taxonomy[0]?.name,
            status: row.impactstatus.name
          })
        }
      })
      policies = _.uniqBy(policies, 'id')
      return policies
    },
    rows: {
      get () {
        return this.$store.state.initiativesPoliciesShow
      },
      set (payload) {
        this.$store.commit('setInitiativesPoliciesShow', payload)
      }
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rows.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      rowsFiltered: [],
      staticDataProperties: [
        { name: 'Areas', property: 'area', graph: 'area', tree: true },
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Policies', property: 'impactpolicy', graph: 'impactpolicy', tree: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true }
      ]
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard"
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style>
</style>
